import React, { useState, useEffect, useCallback } from 'react';
import { Plus, Home, Search, MessageCircle, Menu, Heart, Share2, DollarSign, Newspaper, Eye, User, Diamond, Edit, Users, HelpCircle, Settings, ChevronLeft } from 'lucide-react';
import { fetchFollowingPosts, fetchLikedPosts, fetchPosts } from '../common/mockApi';

// Types
export interface PostProps {
	id: number;
	username: string;
	verified: boolean;
	likes: number;
	views: number;
	images: string[];
}

const Loading: React.FC = () => (
	<div className="flex justify-center items-center h-[calc(100vh-200px)]">
		<div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
	</div>
);

// Header Component
const Header: React.FC = () => (
	<header className="flex justify-between items-center p-4 bg-gray-800 text-white">
		<h1 className="text-xl font-bold">SimpleSub</h1>
		<div>
			<Plus size={24} />
		</div>
	</header>
);

// Navigation Component
const Navigation: React.FC<{ activeTab: string; setActiveTab: (tab: string) => void }> = ({ activeTab, setActiveTab }) => (
	<nav className="flex justify-around items-center p-4 bg-gray-800 text-white">
		<button
			className={`${activeTab === 'for-you' ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
			onClick={() => setActiveTab('for-you')}
		>
			For you
		</button>
		<button
			className={`${activeTab === 'following' ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
			onClick={() => setActiveTab('following')}
		>
			Following
		</button>
		<button
			className={`${activeTab === 'liked' ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
			onClick={() => setActiveTab('liked')}
		>
			Liked
		</button>
	</nav>
);

// Empty Following Feed Component
const EmptyFollowingFeed: React.FC = () => (
	<div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] bg-black text-white">
		<Newspaper size={64} className="mb-4 text-gray-600" />
		<h2 className="text-2xl font-bold mb-2">Feed</h2>
		<p className="text-center text-gray-400 mb-6">
			When you follow someone, their posts will appear here
		</p>
	</div>
);

// Empty Liked Posts Component
const EmptyLikedPosts: React.FC = () => (
	<div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] bg-black text-white">
		<h2 className="text-2xl font-bold mb-2">No liked posts</h2>
		<p className="text-center text-gray-400">
			When you like a post, it will be saved here.
		</p>
	</div>
);

// Footer Component
const Footer: React.FC<{
	activeTab: string;
	setActiveTab: (tab: string) => void;
	setShowProfile: (show: boolean) => void;
}> = ({ activeTab, setActiveTab, setShowProfile }) => (
	<footer className="fixed bottom-0 w-full bg-gray-800 text-white">
		<div className="flex justify-between items-center p-4">
			<div
				className="flex flex-col items-center cursor-pointer"
				onClick={() => { setActiveTab('for-you'); setShowProfile(false); }}
			>
				<Home size={24} color={activeTab === 'for-you' ? "#3B82F6" : "currentColor"} />
				<span className={`text-xs mt-1 ${activeTab === 'for-you' ? 'text-blue-400' : ''}`}>Feed</span>
			</div>
			<div
				className="flex flex-col items-center cursor-pointer"
				onClick={() => setActiveTab('search')}
			>
				<Search size={24} color={activeTab === 'search' ? "#3B82F6" : "currentColor"} />
				<span className={`text-xs mt-1 ${activeTab === 'search' ? 'text-blue-400' : ''}`}>Search</span>
			</div>
			<div
				className="flex flex-col items-center cursor-pointer"
				onClick={() => setActiveTab('messages')}
			>
				<MessageCircle size={24} color={activeTab === 'messages' ? "#3B82F6" : "currentColor"} />
				<span className={`text-xs mt-1 ${activeTab === 'messages' ? 'text-blue-400' : ''}`}>Messages</span>
			</div>
			<div
				className="flex flex-col items-center cursor-pointer"
				onClick={() => { setActiveTab('profile'); setShowProfile(true); }}
			>
				<Menu size={24} color={activeTab === 'profile' ? "#3B82F6" : "currentColor"} />
				<span className={`text-xs mt-1 ${activeTab === 'profile' ? 'text-blue-400' : ''}`}>Profile</span>
			</div>
		</div>
	</footer>
);

// Post Component
const Post: React.FC<PostProps> = ({ id, username, verified, likes, views, images }) => {
	return (
		<div className="bg-gray-800 p-4 mb-4 rounded-lg">
			<div className="flex justify-between items-center mb-4">
				<div className="flex items-center space-x-2">
					<div className="w-10 h-10 bg-purple-600 rounded-full"></div>
					<span className="font-bold">{username}</span>
					{verified && <span className="text-blue-400">✓</span>}
				</div>
				<button className="bg-gray-700 text-white px-4 py-1 rounded hover:bg-gray-600 transition-colors">
					Follow
				</button>
			</div>
			<div className="flex justify-center mb-4">
				<div className={`grid gap-2 ${images.length === 1 ? 'w-full' : 'w-2/3'} ${images.length === 2 ? 'grid-cols-2' : images.length === 3 ? 'grid-cols-3' : ''
					}`}>
					{images.slice(0, 3).map((image, index) => (
						<div key={index} className="aspect-square overflow-hidden rounded-lg">
							<img
								src={image}
								alt={`Post image ${index + 1}`}
								className="w-full h-full object-cover"
							/>
						</div>
					))}
				</div>
			</div>
			<button className="w-full bg-blue-500 text-white py-2 rounded mb-4 hover:bg-blue-600 transition-colors">
				Open for free
			</button>
			<div className="flex justify-between items-center">
				<div className="flex space-x-2">
					<button className="flex items-center space-x-1 px-3 py-1 rounded bg-gray-700 hover:bg-gray-600 transition-colors">
						<Heart size={20} />
						<span>{likes}</span>
					</button>
					<button className="flex items-center space-x-1 px-3 py-1 rounded bg-gray-700 hover:bg-gray-600 transition-colors">
						<Share2 size={20} />
						<span>Share</span>
					</button>
					<button className="flex items-center space-x-1 px-3 py-1 rounded bg-gray-700 hover:bg-gray-600 transition-colors">
						<DollarSign size={20} />
						<span>Tip</span>
					</button>
				</div>
				<div className="flex items-center space-x-1 px-3 py-1 rounded bg-gray-700">
					<Eye size={20} />
					<span>{views}</span>
				</div>
			</div>
		</div>
	);
};

const UnavailableScreen: React.FC = () => (
	<div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
		<h1 className="text-3xl font-bold mb-4">Site is unavailable</h1>
		<p className="text-xl text-center">
			This site is only available via Telegram Web App
		</p>
	</div>
);

interface ProfileProps {
	username: string;
	preferences: string;
	balance: string;
	onBack: () => void;
}

const ProfileItem: React.FC<{ icon: React.ReactNode; text: string; value?: string }> = ({ icon, text, value }) => (
	<div className="flex items-center justify-between py-3 px-4 bg-gray-800 rounded-lg mb-2">
		<div className="flex items-center">
			{icon}
			<span className="ml-3">{text}</span>
		</div>
		{value && <span className="text-gray-400">{value}</span>}
	</div>
);

const Profile: React.FC<ProfileProps> = ({ username, preferences, balance, onBack }) => {
	return (
		<div className="bg-black min-h-screen text-white p-4">
			<div className="flex items-center mb-4">
				<ChevronLeft size={24} onClick={onBack} className="cursor-pointer" />
				<h1 className="text-2xl font-bold ml-4">More</h1>
			</div>

			<div className="bg-gray-800 rounded-lg p-4 mb-4">
				<div className="flex items-center">
					<div className="w-12 h-12 bg-gray-600 rounded-full mr-4"></div>
					<div>
						<h2 className="font-bold">{username}</h2>
						<p className="text-blue-400">Go to profile &gt;</p>
					</div>
				</div>
			</div>

			<ProfileItem icon={<User size={20} />} text="My preferences" value={preferences} />
			<ProfileItem icon={<DollarSign size={20} />} text="Balance" value={balance} />
			<ProfileItem icon={<Diamond size={20} color="#3B82F6" />} text="Subscriptions" />
			<ProfileItem icon={<DollarSign size={20} />} text="Purchased Content" />
			<ProfileItem icon={<Edit size={20} />} text="Edit profile" />

			<div className="mt-4">
				<ProfileItem icon={<Users size={20} />} text="Referral program" />
				<ProfileItem icon={<Diamond size={20} color="#3B82F6" />} text="Become a creator (to earn)" />
				<ProfileItem icon={<HelpCircle size={20} />} text="Help" />
				<ProfileItem icon={<Settings size={20} />} text="Settings" />
			</div>
		</div>
	);
};

interface FeedProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
}

const Feed: React.FC<FeedProps> = ({ activeTab, setActiveTab }) => {
	const [posts, setPosts] = useState<PostProps[]>([]);
	const [likedPosts, setLikedPosts] = useState<PostProps[]>([]);
	const [followingPosts, setFollowingPosts] = useState<PostProps[]>([]);
	const [loading, setLoading] = useState(false);

	const loadPosts = useCallback(async () => {
		setLoading(true);
		setPosts([]);
		try {
			const newPosts = await fetchPosts(1);
			setPosts(newPosts);
		} catch (error) {
			console.error('Error fetching posts:', error);
		}
		setLoading(false);
	}, []);

	const loadLikedPosts = useCallback(async () => {
		setLoading(true);
		setLikedPosts([]);
		try {
			const posts = await fetchLikedPosts();
			setLikedPosts(posts);
		} catch (error) {
			console.error('Error fetching liked posts:', error);
		}
		setLoading(false);
	}, []);

	const loadFollowingPosts = useCallback(async () => {
		setLoading(true);
		setFollowingPosts([]);
		try {
			const posts = await fetchFollowingPosts();
			setFollowingPosts(posts);
		} catch (error) {
			console.error('Error fetching following posts:', error);
		}
		setLoading(false);
	}, []);

	useEffect(() => {
		if (activeTab === 'for-you') {
			loadPosts();
		} else if (activeTab === 'following') {
			loadFollowingPosts();
		} else if (activeTab === 'liked') {
			loadLikedPosts();
		}
	}, [activeTab, loadPosts, loadFollowingPosts, loadLikedPosts]);

	const renderContent = () => {
		if (loading) return <Loading />;

		switch (activeTab) {
			case 'for-you':
				return posts.map((post) => <Post key={post.id} {...post} />);
			case 'following':
				return followingPosts.length > 0 ?
					followingPosts.map((post) => <Post key={post.id} {...post} />) :
					<EmptyFollowingFeed />;
			case 'liked':
				return likedPosts.length > 0 ?
					likedPosts.map((post) => <Post key={post.id} {...post} />) :
					<EmptyLikedPosts />;
			default:
				return null;
		}
	};

	return (
		<div>
			<div className="flex justify-around items-center p-4 bg-gray-800 text-white">
				<button
					className={`${activeTab === 'for-you' ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
					onClick={() => setActiveTab('for-you')}
				>
					For you
				</button>
				<button
					className={`${activeTab === 'following' ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
					onClick={() => setActiveTab('following')}
				>
					Following
				</button>
				<button
					className={`${activeTab === 'liked' ? 'text-blue-400 border-b-2 border-blue-400' : 'text-gray-400'}`}
					onClick={() => setActiveTab('liked')}
				>
					Liked
				</button>
			</div>
			<div className="pb-20">
				{renderContent()}
			</div>
		</div>
	);
};


// @ts-ignore

// Main App Component
const App: React.FC = () => {
	const [isTelegramWebApp, setIsTelegramWebApp] = useState<boolean>(false);
	const [activeTab, setActiveTab] = useState('for-you');
	const [showProfile, setShowProfile] = useState(false);

	useEffect(() => {
		const checkTelegramWebApp = () => {
			// setIsTelegramWebApp(true);
			// @ts-ignore
			if (window.Telegram && window.Telegram.WebApp) {
				// @ts-ignore
				const initData = window.Telegram.WebApp.initData;
				if (initData)
					setIsTelegramWebApp(true);
			}
		};

		checkTelegramWebApp();
	}, []);

	if (!isTelegramWebApp) {
		return <UnavailableScreen />;
	}

	return (
		<div className="bg-black min-h-screen text-white">
			{!showProfile && <Header />}
			<main>
				{showProfile ? (
					<Profile
						username="Simple Test"
						preferences="Girls"
						balance="€0"
						onBack={() => setShowProfile(false)}
					/>
				) : (
					<Feed activeTab={activeTab} setActiveTab={setActiveTab} />
				)}
			</main>
			<Footer activeTab={activeTab} setActiveTab={setActiveTab} setShowProfile={setShowProfile} />
		</div>
	);
};

export default App;