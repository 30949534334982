import { PostProps } from "../main/Main";

const mockNatureImages = [
  'https://images.unsplash.com/photo-1501854140801-50d01698950b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/photo-1426604966848-d7adac402bff?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/photo-1472214103451-9374bd1c798e?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/photo-1490730141103-6cac27aaab94?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60',
  'https://images.unsplash.com/photo-1501785888041-af3ef285b470?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
];

// Updated mockApi.ts
const generateMockPosts = (count: number): PostProps[] => {
  return Array.from({ length: count }, (_, i) => ({
    id: i + 1,
    username: `User${i + 1}`,
    verified: Math.random() > 0.5,
    likes: Math.floor(Math.random() * 1000),
    views: Math.floor(Math.random() * 10000),
    images: Array.from(
      { length: Math.floor(Math.random() * 3) + 1 },
      () => mockNatureImages[Math.floor(Math.random() * mockNatureImages.length)]
    ),
  }));
};

const mockPosts = generateMockPosts(30);
const mockLikedPosts = mockPosts.slice(0, 5);  // Первые 5 постов будут "лайкнутыми"
const mockFollowingPosts = mockPosts.slice(10, 20);  // Посты с 11 по 20 будут от "подписок"

export const fetchPosts = async (page: number, limit: number = 10): Promise<PostProps[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const start = (page - 1) * limit;
      const end = start + limit;
      const paginatedPosts = mockPosts.slice(start, end);
      resolve(paginatedPosts);
    }, 500);
  });
};

export const fetchLikedPosts = async (): Promise<PostProps[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      // resolve(mockLikedPosts);
      resolve([]);
    }, 500);
  });
};

export const fetchFollowingPosts = async (): Promise<PostProps[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      // resolve(mockFollowingPosts);
      resolve([]);
    }, 500);
  });
};